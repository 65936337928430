// File: src/components/AppFooter.tsx

import React from 'react';
import Andr3wActiveFooterLogo from './Andr3wActiveFooterLogo';

const AppFooter: React.FC = () => {
    return (
        <footer className="flex align-items-center justify-content-between p-3 mt-6 common-aug-mixin common-aug-bl-mixin" style={{ width: '100%', height: 'auto' }} data-augmented-ui="tl-clip tr-clip both">
            <div className="flex align-items-center">
                {/* <div onClick={handleFooterLogoClick} style={{ cursor: 'pointer' }}> */}
                    <Andr3wActiveFooterLogo />
                {/* </div> */}
                <p className="m-0 ml-6">
              © {new Date().getFullYear()} ANDR3W.ai | All Rights Reserved |{' '}
              <a
                href="https://andr3w.ai/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-500 cursor-pointer hover:text-yellow-300"
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </a>{' '}
              |{' '}
              <a
                href="https://andr3w.ai/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-500 cursor-pointer hover:text-yellow-300"
                style={{ textDecoration: 'underline' }}
              >
                Terms of Service
              </a>
              <br />
              <span className="font-italic text-white-alpha-50">Google and the Google logo are registered trademarks of Google LLC.</span>
            </p>
            </div>
        </footer>
    );
};

export default AppFooter;
