// File: app/frontend/src/components/GoogleCalendarConnect.tsx

import "primereact/resources/themes/mdc-dark-indigo/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "../styles/global.css";
import "../styles/App.css";

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Calendar as PRCalendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'; // Import ConfirmPopup

import { Chips } from 'primereact/chips';
import ConsoleHeader from "./ConsoleHeader";
import AppFooter from "./AppFooter";

const BASE_URL = process.env.REACT_APP_API_URL;

interface Agent {
    agentId: string;
    agentCompanyName: string;
}

interface ConnectionStatusResponse {
    isConnected: boolean;
    reauthorizationRequired: boolean;
}

interface CalendarEvent {
    id: string;
    summary: string;
    description: string;
    startTime: string;
    endTime: string;
    attendees: string[];
}

interface NewCalendarEvent {
    summary: string;
    description?: string;
    startTime: Date | null;
    endTime: Date | null;
    attendees: string[];
}

interface CalendarIntegrationProps {
    isEmbedded?: boolean;
}

const CalendarIntegration: React.FC<CalendarIntegrationProps> = ({ isEmbedded = false }) => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const location = useLocation();
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [reauthorizationRequired, setReauthorizationRequired] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [agents, setAgents] = useState<Agent[]>([]);
    const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
    const [dateRange, setDateRange] = useState<Date[] | null>(null);
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const [agentStatuses, setAgentStatuses] = useState<Map<string, ConnectionStatusResponse>>(new Map());
    
    const [newEvent, setNewEvent] = useState<NewCalendarEvent>({
        summary: '',
        description: '',
        startTime: null,
        endTime: null,
        attendees: [],
    });
    const toast = useRef<Toast>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const checkConnectionStatus = useCallback(
        async (agentId: string) => {
            const status = agentStatuses.get(agentId);
            if (status) {
                setIsConnected(status.isConnected);
                setReauthorizationRequired(status.reauthorizationRequired);

                if (status.reauthorizationRequired) {
                    showToast('warn', 'Reauthorization Required', 'Please reconnect your Google Calendar.');
                }
            } else {
                try {
                    setIsLoading(true);
                    const token = await getAccessTokenSilently();
                    const response = await fetch(`${BASE_URL}/api/calendar/status?agentId=${agentId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

                    const data: ConnectionStatusResponse = await response.json();
                    setIsConnected(data.isConnected);
                    setReauthorizationRequired(data.reauthorizationRequired);

                    if (data.reauthorizationRequired) {
                        showToast('warn', 'Reauthorization Required', 'Please reconnect your Google Calendar.');
                    }
                } catch (error) {
                    showToast('error', 'Error', 'Failed to check calendar status');
                } finally {
                    setIsLoading(false);
                }
            }
        },
        [getAccessTokenSilently, agentStatuses]
    );

    // Fetch agent statuses after fetching agents
    useEffect(() => {
        const fetchAgentStatuses = async () => {
            if (agents.length > 0) {
                try {
                    setIsLoading(true);
                    const token = await getAccessTokenSilently();
                    const statuses = new Map<string, ConnectionStatusResponse>();
                    for (const agent of agents) {
                        const response = await fetch(`${BASE_URL}/api/calendar/status?agentId=${agent.agentId}`, {
                            headers: { Authorization: `Bearer ${token}` },
                        });
                        if (response.ok) {
                            const data: ConnectionStatusResponse = await response.json();
                            statuses.set(agent.agentId, data);
                        } else {
                            // Handle errors if needed
                            statuses.set(agent.agentId, { isConnected: false, reauthorizationRequired: false });
                        }
                    }
                    setAgentStatuses(statuses);
                } catch (error) {
                    console.error('Error fetching agent statuses:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchAgentStatuses();
    }, [agents, getAccessTokenSilently]);

    // Select agent with expired calendar access
    useEffect(() => {
        if (agents.length > 0 && agentStatuses.size > 0) {
            const storedAgentId = sessionStorage.getItem('selectedAgentId');
            let agent = null;

            if (storedAgentId) {
                agent = agents.find((a) => a.agentId === storedAgentId);
            }

            const agentWithExpiredAccess = agents.find((a) => {
                const status = agentStatuses.get(a.agentId);
                return status?.reauthorizationRequired;
            });

            if (!agent && agentWithExpiredAccess) {
                agent = agentWithExpiredAccess;
                sessionStorage.setItem('selectedAgentId', agent.agentId);
            }

            if (!agent && agents.length === 1) {
                agent = agents[0];
                sessionStorage.setItem('selectedAgentId', agent.agentId);
            }

            if (agent) {
                setSelectedAgent(agent);
                const status = agentStatuses.get(agent.agentId);
                if (status) {
                    setIsConnected(status.isConnected);
                    setReauthorizationRequired(status.reauthorizationRequired);
                } else {
                    checkConnectionStatus(agent.agentId);
                }
            }
        }
    }, [agents, agentStatuses]);

    const handleRedirect = useCallback(
        async (code: string) => {
            if (sessionStorage.getItem('codeExchanged') === 'true') {
                return;
            }

            sessionStorage.setItem('codeExchanged', 'true');

            const agentId = sessionStorage.getItem('selectedAgentId');

            if (!agentId) {
                console.log('Missing agentId, skipping code exchange.');
                return;
            }

            try {
                setIsLoading(true);
                const token = await getAccessTokenSilently();

                const authorizationCodeRequest = {
                    AuthorizationCode: code,
                    AgentId: agentId,
                };

                const response = await fetch(`${BASE_URL}/api/calendar/exchange-code`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(authorizationCodeRequest),
                });

                if (!response.ok) {
                    const errorDetails = await response.json();
                    console.error('Backend error details:', errorDetails);
                    throw new Error(`Failed to exchange authorization code: ${response.statusText}`);
                }

                const responseData = await response.json();

                setIsConnected(true);
                setReauthorizationRequired(false);

                // Display a Toast message with the Agent's company name
                const agent = agents.find((a) => a.agentId === agentId);
                if (agent) {
                    showToast('success', 'Success', `Google Calendar connected successfully for ${agent.agentCompanyName}`);
                    setSelectedAgent(agent);
                } else {
                    showToast('success', 'Success', 'Google Calendar connected successfully');
                }
            } catch (error) {
                console.error('Google Calendar connection error:', error);
                showToast(
                    'error',
                    'Error',
                    error instanceof Error ? error.message : 'Failed to connect Google Calendar'
                );
            } finally {
                setIsLoading(false);
                sessionStorage.removeItem('codeExchanged');
                // Clear the URL parameters after processing
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        },
        [getAccessTokenSilently, agents]
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        if (code) {
            handleRedirect(code);
        }
    }, [location.search, handleRedirect]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchAgents();
        }
    }, [isAuthenticated]);

    const fetchAgents = useCallback(async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();

            const response = await fetch(`${BASE_URL}/api/agent/tenantagents`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) {
                const errorDetails = await response.text();
                console.error('Failed to fetch agents:', errorDetails);
                throw new Error(`Failed to fetch agents: ${response.statusText}`);
            }

            const data: Agent[] = await response.json();
            setAgents(data);
        } catch (error) {
            showToast('error', 'Error', error instanceof Error ? error.message : 'Failed to fetch agents');
            console.error('Error in fetchAgents:', error);
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently]);

    const handleAgentChange = (e: { value: Agent }) => {
        setSelectedAgent(e.value);
        if (e.value?.agentId) {
            sessionStorage.setItem('selectedAgentId', e.value.agentId);

            const status = agentStatuses.get(e.value.agentId);
            if (status) {
                setIsConnected(status.isConnected);
                setReauthorizationRequired(status.reauthorizationRequired);
            } else {
                checkConnectionStatus(e.value.agentId);
            }
        }
    };

    const handleConnect = async () => {
        // Clear the codeExchanged flag
        sessionStorage.removeItem('codeExchanged');

        if (!selectedAgent || !selectedAgent.agentId) {
            showToast('warn', 'Warning', 'Please select an agent before connecting');
            return;
        }

        // Store the selected agent ID in session storage
        sessionStorage.setItem('selectedAgentId', selectedAgent.agentId);

        // Construct the Google OAuth URL
        const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        if (!googleClientId) {
            showToast('error', 'Error', 'Google Client ID is not configured');
            return;
        }

        //const redirectUri = `${window.location.origin}/google-calendar`;
        // const redirectUri = `${window.location.origin}`;
        // const scope = encodeURIComponent(
        //     'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'
        // );
        // const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${encodeURIComponent(
        //     redirectUri
        // )}&response_type=code&scope=${scope}&access_type=offline&prompt=consent`;

        // ANDR3W AI has verified Google scopes for https://www.googleapis.com/auth/calendar only.
        const redirectUri = `${window.location.origin}`;
        const scope = encodeURIComponent(
            'https://www.googleapis.com/auth/calendar'
        );
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${encodeURIComponent(
            redirectUri
        )}&response_type=code&scope=${scope}&access_type=offline&prompt=consent`;

        // Redirect the user to Google's OAuth 2.0 authorization endpoint
        window.location.href = authUrl;
    };

    const handleDisconnect = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!selectedAgent || !selectedAgent.agentId) {
            showToast('warn', 'Warning', 'Please select an agent before disconnecting');
            return;
        }

        const confirmDisconnect = () => {
            performDisconnect();
        };

        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to disconnect your calendar?',
            icon: 'pi pi-exclamation-triangle',
            accept: confirmDisconnect,
            reject: () => { },
            appendTo: document.body,
        });
    };

    const performDisconnect = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();

            const response = await fetch(
                `${BASE_URL}/api/calendar/disconnect?agentId=${selectedAgent!.agentId}`,
                {
                    method: 'POST',
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (!response.ok) {
                const errorDetails = await response.json();
                console.error('Backend error details:', errorDetails);
                throw new Error(`Failed to disconnect calendar: ${response.statusText}`);
            }

            showToast('success', 'Disconnected', 'Google Calendar disconnected successfully');
            setIsConnected(false);
            setReauthorizationRequired(false);
            setEvents([]);
        } catch (error) {
            console.error('Error disconnecting calendar:', error);
            showToast(
                'error',
                'Error',
                error instanceof Error ? error.message : 'Failed to disconnect calendar'
            );
        } finally {
            setIsLoading(false);
        }
    };

    // Updated handleFetchEvents function
    const handleFetchEvents = async () => {
        if (!selectedAgent || !selectedAgent.agentId) {
            showToast('warn', 'Warning', 'Please select an agent');
            return;
        }

        if (!dateRange || dateRange.length !== 2) {
            showToast('warn', 'Warning', 'Please select a valid date range');
            return;
        }

        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();

            const startDate = dateRange[0].toISOString();
            const endDate = dateRange[1].toISOString();

            const response = await fetch(
                `${BASE_URL}/api/calendar/events?agentId=${selectedAgent.agentId}&startDate=${startDate}&endDate=${endDate}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (!response.ok) {
                if (response.status === 401) {
                    showToast('error', 'Unauthorized', 'Your session has expired. Please log in again.');
                    // Optionally, trigger re-authentication
                } else if (response.status === 404) {
                    showToast('info', 'No Events Found', 'No events were found for the selected date range.');
                    setEvents([]);
                } else {
                    const errorDetails = await response.json();
                    console.error('Error fetching events:', errorDetails);
                    showToast('error', 'Error', errorDetails.message || 'Failed to fetch events');
                }
                return;
            }

            const eventsData: CalendarEvent[] = await response.json();
            setEvents(eventsData);

            if (eventsData.length > 0) {
                showToast('success', 'Events Fetched', `Fetched ${eventsData.length} events`);
            } else {
                showToast('info', 'No Events Found', 'No events were found for the selected date range.');
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            showToast(
                'error',
                'Error',
                error instanceof Error ? error.message : 'An unexpected error occurred while fetching events'
            );
        } finally {
            setIsLoading(false);
        }
    };


    const handleCreateEvent = () => {
        setNewEvent({
            summary: '',
            description: '',
            startTime: null,
            endTime: null,
            attendees: [],
        });
        setShowCreateDialog(true);
    };

    const validateEvent = () => {
        const newErrors: { [key: string]: string } = {};

        if (!newEvent.summary.trim()) {
            newErrors.summary = 'Summary is required.';
        }
        if (!newEvent.startTime) {
            newErrors.startTime = 'Start Time is required.';
        }
        if (!newEvent.endTime) {
            newErrors.endTime = 'End Time is required.';
        }
        if (newEvent.startTime && newEvent.endTime && newEvent.startTime >= newEvent.endTime) {
            newErrors.endTime = 'End Time must be after Start Time.';
        }

        // Validate attendee emails (optional)
        const invalidEmails = newEvent.attendees.filter(
            (email) => !/^\S+@\S+\.\S+$/.test(email)
        );
        if (invalidEmails.length > 0) {
            newErrors.attendees = 'One or more attendee emails are invalid.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const saveNewEvent = async () => {
        if (!validateEvent()) {
            showToast('warn', 'Validation Failed', 'Please correct the errors in the form.');
            return;
        }

        if (!selectedAgent || !selectedAgent.agentId) {
            showToast('warn', 'Warning', 'Please select an agent');
            return;
        }

        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();

            const eventRequest = {
                summary: newEvent.summary,
                description: newEvent.description || '',
                startTime: newEvent.startTime?.toISOString(),
                endTime: newEvent.endTime?.toISOString(),
                attendees: newEvent.attendees || [],
            };

            const response = await fetch(
                `${BASE_URL}/api/calendar/events?agentId=${selectedAgent.agentId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(eventRequest),
                }
            );

            if (!response.ok) {
                const errorDetails = await response.json();
                console.error('Error creating event:', errorDetails);
                showToast('error', 'Error', errorDetails.message || 'Failed to create event');
                return;
            }

            showToast('success', 'Event Created', 'Your event has been created successfully');
            setShowCreateDialog(false);

            // Refresh events if date range is selected
            if (dateRange && dateRange.length === 2) {
                await handleFetchEvents();
            }
        } catch (error) {
            console.error('Error creating event:', error);
            showToast(
                'error',
                'Error',
                error instanceof Error ? error.message : 'An unexpected error occurred while creating the event'
            );
        } finally {
            setIsLoading(false);
        }
    };


    const onInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        name: string
    ) => {
        setNewEvent({ ...newEvent, [name]: e.target.value });

        // Clear error for this field
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };

    const onDateChange = (e: any, name: string) => {
        setNewEvent({ ...newEvent, [name]: e.value });

        // Clear error for this field
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };

    const onChipsChange = (e: any, name: string) => {
        setNewEvent({ ...newEvent, [name]: e.value });

        // Clear error for attendee emails
        if (errors.attendees) {
            setErrors({ ...errors, attendees: '' });
        }
    };


    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => setShowCreateDialog(false)}
                    className="p-button-text"
                />
                <Button label="Create" icon="pi pi-check" onClick={saveNewEvent} autoFocus />
            </div>
        );
    };

    const showToast = (
        severity: 'success' | 'info' | 'warn' | 'error',
        summary: string,
        detail: string,
        life: number = 5000
    ) => {
        toast.current?.show({ severity, summary, detail, life });
    };

    return (
        <div>
            {!isEmbedded && <Toast ref={toast} />}
            {!isEmbedded && <ConsoleHeader />}
            <ConfirmPopup />
            <div data-augmented-ui={isEmbedded ? "" : "tl-clip tr-clip-x br-clip-x br-clip-inset b-clip-x bl-clip border"}
                className={isEmbedded ? "m-0 p-0" : "m-3 common-aug-mixin common-aug-tl-mixin w-full"}
                style={isEmbedded ? { height: '400px' } : { height: '800px' }}
            >
                {!isEmbedded && (
                    <Card
                        title="Google® Calendar Integration"
                        className={isEmbedded ? "p-0 m-0 w-25rem card bg-transparent" : "p-3 card bg-transparent"}
                    >
                    </Card>
                )}
                {isLoading && (
                    <div className="p-d-flex p-jc-center p-ai-center" style={{ minHeight: '100px' }}>
                        <ProgressSpinner />
                    </div>
                )}
                {/* Controls Section */}
                <div className={isEmbedded ? "flex flex-wrap gap-3" : "p-3 flex flex-wrap gap-3"}>
                    {/* Agent Selection */}
                    <div className="mt-2">
                        <FloatLabel>
                            <label htmlFor="agent">Select Agent</label>
                            <Dropdown
                                id="agent"
                                value={selectedAgent}
                                options={agents.map((agent) => ({
                                    label: agent.agentCompanyName,
                                    value: agent,
                                }))}
                                onChange={handleAgentChange}
                                placeholder="Select your Agent"
                                className="p-inputtext-lg w-26rem"
                            />
                        </FloatLabel>
                    </div>

                    {/* Conditionally render Date Range and Buttons if agent is selected and connected */}
                    {selectedAgent && isConnected && !reauthorizationRequired && (
                        <>
                            {/* Date Range Selection */}
                            {!isEmbedded &&
                                <div className="mt-2">
                                    <FloatLabel>
                                        <label htmlFor="dateRange">Select Date Range</label>
                                        <PRCalendar
                                            id="dateRange"
                                            selectionMode="range"
                                            value={dateRange}
                                            onChange={(e) => setDateRange(e.value as Date[])}
                                            showIcon
                                            readOnlyInput
                                            appendTo={document.body}
                                            dateFormat="yy-mm-dd"
                                            className="p-inputtext-lg w-20rem"
                                        />
                                    </FloatLabel>
                                </div>
                            }

                            {/* Buttons */}
                            {!isEmbedded &&
                                <div className="gap-2">
                                    <Button
                                        label="Fetch Events"
                                        icon="pi pi-refresh"
                                        onClick={handleFetchEvents}
                                        className="p-button-info"
                                        disabled={isLoading}
                                    />
                                    <Button
                                        label="Create New Event"
                                        icon="pi pi-plus"
                                        onClick={handleCreateEvent}
                                        className="p-button-success ml-2"
                                        disabled={isLoading}
                                    />
                                </div>
                            }
                            <Button
                                label="Disconnect"
                                icon="pi pi-calendar-times"
                                onClick={handleDisconnect}
                                className="p-button-danger ml-2"
                                disabled={isLoading}
                            />
                        </>
                    )}
                </div>

                {/* Messages and Connect Button */}
                {selectedAgent && (!isConnected || reauthorizationRequired) && (
                    <div className={isEmbedded ? "mt-3 flex flex-column" : "p-3 flex flex-column"}>
                        {reauthorizationRequired ? (
                            <Message
                                severity="warn"
                                text="Your calendar access has expired. Please reconnect."
                                className="mb-2"
                            />
                        ) : (
                            <Message
                                severity="info"
                                text="Connect your Calendar to allow ANDR3W to manage your calendar events."
                                className="mb-2 w-26rem"
                            />
                        )}
                        {/* <Message
                                severity="info"
                                text="By connecting your Google Calendar, you allow ANDR3W to manage your calendar events."
                                className="mb-2"
                            /> */}
                        <Button
                            label="Connect Calendar"
                            icon="pi pi-calendar"
                            onClick={handleConnect}
                            className="p-button-warning w-18rem"
                            disabled={isLoading}
                        />
                    </div>
                )}

                {/* Events Table */}
                {!isEmbedded && selectedAgent && isConnected && !reauthorizationRequired && (
                    <div className="mt-8" data-augmented-ui="tl-clip tr-clip br-clip bl-clip border">
                        {events.length > 0 ? (
                            <DataTable
                                value={events}
                                paginator
                                rows={30}
                                responsiveLayout="scroll"
                                emptyMessage="No events found."
                                style={{ width: '100%' }}
                            >
                                <Column field="summary" header="Summary" sortable />
                                <Column field="description" header="Description" sortable />
                                <Column
                                    field="startTime"
                                    header="Start Time"
                                    body={(rowData) =>
                                        new Date(rowData.startTime).toLocaleString()
                                    }
                                    sortable
                                />
                                <Column
                                    field="endTime"
                                    header="End Time"
                                    body={(rowData) =>
                                        new Date(rowData.endTime).toLocaleString()
                                    }
                                    sortable
                                />
                                <Column
                                    field="attendees"
                                    header="Attendees"
                                    body={(rowData) => rowData.attendees.join(', ')}
                                />
                            </DataTable>
                        ) : (
                            <Message
                                severity="info"
                                text="No events found for the selected date range."
                                className="mt-4"
                            />
                        )}
                    </div>
                )}
                {/* </Card> */}
            </div>

            {/* Create Event Dialog */}
            <Dialog
                header="Create New Event"
                visible={showCreateDialog}
                style={{ width: '50vw' }}
                footer={renderFooter()}
                onHide={() => setShowCreateDialog(false)}
                modal
                closable={!isLoading}
                className="border-yellow-300"
                data-augmented-ui="tl-clip tr-clip br-clip bl-clip border"
            >
                <div>
                    <div className="mt-4">
                        <FloatLabel>
                            <label htmlFor="summary">Title*</label>
                            <InputText
                                id="summary"
                                value={newEvent.summary}
                                onChange={(e) => onInputChange(e, 'summary')}
                                className={errors.summary ? 'p-invalid' : 'w-8'}
                            />
                        </FloatLabel>
                        {errors.summary && <small className="p-error">{errors.summary}</small>}
                    </div>
                    <div className="mt-4">
                        <FloatLabel>
                            <label htmlFor="description">Description</label>
                            <InputText
                                id="description"
                                value={newEvent.description}
                                onChange={(e) => onInputChange(e, 'description')}
                                className='w-11'
                            />
                        </FloatLabel>
                    </div>
                    <div className="p-grid">
                        <div className="mt-4">
                            <label htmlFor="startTime">Start Time*</label><br />
                            <PRCalendar
                                id="startTime"
                                value={newEvent.startTime}
                                onChange={(e) => onDateChange(e, 'startTime')}
                                showIcon
                                showTime
                                appendTo={document.body}
                                dateFormat="yy-mm-dd"
                                className={errors.startTime ? 'p-invalid' : 'w-11 mt-2'}
                            />
                            {errors.startTime && <small className="p-error">{errors.startTime}</small>}
                        </div>
                        <div className="mt-4">
                            <label htmlFor="endTime">End Time*:</label><br />
                            <PRCalendar
                                id="endTime"
                                value={newEvent.endTime}
                                onChange={(e) => onDateChange(e, 'endTime')}
                                showIcon
                                showTime
                                appendTo={document.body}
                                dateFormat="yy-mm-dd"
                                className={errors.endTime ? 'p-invalid' : 'w-11 mt-2'}
                            />
                            {errors.endTime && <small className="p-error">{errors.endTime}</small>}
                        </div>
                    </div>
                    <div className="mt-4">
                        <label htmlFor="attendees">Attendees (Emails)</label>
                        <Chips
                            id="attendees"
                            value={newEvent.attendees}
                            onChange={(e) => onChipsChange(e, 'attendees')}
                            placeholder="Enter attendee emails"
                            separator=","
                            allowDuplicate={false}
                            className={errors.attendees ? 'p-invalid w-11' : 'w-11'}
                        />
                        {errors.attendees && (
                            <small className="p-error">{errors.attendees}</small>
                        )}
                    </div>
                </div>
            </Dialog>

            {!isEmbedded && <AppFooter />}
        </div>
    );

};

export default CalendarIntegration;
