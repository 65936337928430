// ConversationLogs.tsx
import React, { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { ToggleButton } from 'primereact/togglebutton';
import { Toast } from 'primereact/toast';
import { FloatLabel } from 'primereact/floatlabel';
import html2canvas from 'html2canvas';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "../styles/global.css";
import "../styles/App.css";
import './ConversationLogs.css'; // Import the CSS file for styles
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

interface ConversationLogsProps {
  tenantId: string | null;
}

const BASE_URL = process.env.REACT_APP_API_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}

interface MessageData {
  Role: string;
  Content: string;
  Timestamp: string;
  Tag: string;
  DurationMs: number;
  RelatedObjectiveId: string | null;
  AiGenDurationMs: number;
  IsMessageReceived: boolean;
  OperationContextJson: string;
}

interface ConversationJsonData {
  ConversationId: string;
  Messages: MessageData[];
}

interface CallData {
  detailId: string;
  callListId: string;
  doNotCallList: boolean;
  targetResponseCount: number;
  callDurationSeconds: number;
  targetPhoneNumberE164: string;
  callEndTime: string;
  companyName: string;
  callCount: number;
  industry: string;
  targetAnswered: boolean;
  state: string;
  callResult: string;
  answeredByIVR: boolean;
  callErrors: string;
  callType: string;
  conversationText: string;
  conversationJson: string; // Updated field
}

const ConversationLogs: React.FC<ConversationLogsProps> = ({ tenantId }) => {
  const { getAccessTokenSilently } = useAuth0();

  // State variables
  const [callData, setCallData] = useState<CallData[]>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Date and filter states
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [calledByDate, setCalledByDate] = useState<Date>(new Date(new Date().setHours(23, 59, 59, 999)));
  const [targetResponseCount, setTargetResponseCount] = useState<number>(4);
  const [answeredByIVR, setAnsweredByIVR] = useState<boolean>(false);
  const [includeOutboundCalls, setIncludeOutboundCalls] = useState<boolean>(true);
  const [includeInboundCalls, setIncludeInboundCalls] = useState<boolean>(true);

  const toastRef = useRef<Toast>(null);

  // Function to count inbound and outbound calls
  const countInboundCalls = () =>
    callData.filter((data) => data.callType === "Inbound").length;
  const countOutboundCalls = () =>
    callData.filter((data) => data.callType === "Outbound").length;

  const handleFetchCallData = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 8000);

    if (!tenantId) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Tenant ID is required to fetch important call data.',
        life: 3000
      });
      setLoading(false);
      return;
    }

    const calledAfterDate = selectedDate.toISOString();
    const calledByDateString = calledByDate ? calledByDate.toISOString() : null;
    try {
      const query = new URLSearchParams({
        calledAfterDate,
        targetResponseCount: targetResponseCount.toString(),
        answeredByIVR: answeredByIVR ? "true" : "false",
        includeOutboundCalls: includeOutboundCalls ? "true" : "false",
        includeInboundCalls: includeInboundCalls ? "true" : "false",
      });

      if (calledByDateString) {
        query.append("calledByDate", calledByDateString);
      }

      console.log("Fetching call data with params:", query.toString());

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email"
        }
      });

      const response = await fetch(
        `${BASE_URL}/api/calldata?${query.toString()}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
        },
      );

      if (!response.ok) {
        const errorDetail = await response.text();
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: errorDetail || 'Failed to fetch call data',
          life: 3000
        });
        throw new Error(errorDetail || "Failed to fetch call data");
      }

      const data = await response.json();
      setCallData(data);
      setVisible(true);
    } catch (error) {
      console.error("Error fetching important call data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCallDetailToggleChange = async (id: string | undefined, field: string, value: boolean, table: 'CallDetails' | 'CallLists') => {
    if (!id) {
      console.error('Invalid ID for toggle change');
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Invalid ID for toggle change',
        life: 3000
      });
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email"
        }
      });

      const response = await fetch(`${BASE_URL}/api/updatecallfield`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ id, field, value, table }),
      });

      if (!response.ok) {
        throw new Error('Failed to update call field');
      }

      // Update the local state to reflect the change
      setCallData(prevData =>
        prevData.map(call =>
          (table === 'CallDetails' && call.detailId === id) || (table === 'CallLists' && call.callListId === id)
            ? { ...call, [field]: value }
            : call
        )
      );

      // Show success toast
      toastRef.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: `${field} updated successfully`,
        life: 3000
      });
    } catch (error) {
      console.error('Error updating call field:', error);
      // Show error toast
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: `Failed to update ${field}`,
        life: 3000
      });
    }
  };

  const captureAndCopyToClipboard = async (elementId: string) => {
    try {
      const element = document.getElementById(elementId);
      if (!element) {
        throw new Error('Element not found');
      }

      const canvas = await html2canvas(element, {
        backgroundColor: getComputedStyle(element).backgroundColor,
        useCORS: true,
        scale: 2, // Increase resolution
      });

      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            await navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob
              })
            ]);
            toastRef.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Call details copied to clipboard as image',
              life: 3000
            });
          } catch (err) {
            console.error('Failed to copy image: ', err);
            toastRef.current?.show({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to copy image to clipboard',
              life: 3000
            });
          }
        }
      }, 'image/png');

    } catch (error) {
      console.error('Error capturing screen:', error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to capture call details',
        life: 3000
      });
    }
  };

  const handleRefresh = async () => {
    await handleFetchCallData();
  };

  // Function to render conversation messages in iMessage style
  const renderConversationMessages = (conversationJson: string) => {
    try {
      const conversation: ConversationJsonData = JSON.parse(conversationJson);

      // Group messages by sender
      const messages = conversation.Messages;
      const groupedMessages = [];
      let currentGroup = { sender: '', messages: [] as MessageData[] };

      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        const sender = message.Role;
        if (currentGroup.sender !== sender) {
          if (currentGroup.messages.length > 0) {
            groupedMessages.push({ ...currentGroup });
          }
          currentGroup = { sender, messages: [message] };
        } else {
          currentGroup.messages.push(message);
        }
      }
      if (currentGroup.messages.length > 0) {
        groupedMessages.push({ ...currentGroup });
      }

      return (
        <div className="conversation-container">
          {groupedMessages.map((group, index) => (
            <div key={index} className="message-group">
              <small
                className={`sender-label ${group.sender === 'Agent' ? 'agent-label' : 'human-label'
                  }`}
              >
                {group.sender}{" "}
                <span style={{ fontStyle: 'italic' }}>
                  [{group.messages[0].Tag} {group.messages[0].AiGenDurationMs}ms]
                </span>
              </small>
              {group.messages.map((message, msgIndex) => (
                <div
                  key={msgIndex}
                  className={`message ${group.sender === 'Agent' ? 'agent' : 'human'
                    }`}
                >
                  <div className="message-bubble">
                    <p>{message.Content}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    } catch (error) {
      console.error('Error parsing conversation JSON:', error);
      return <p>Error displaying conversation.</p>;
    }
  };

  return (
    <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
      <div data-augmented-ui="tr-clip bl-clip-x bl-clip-inset br-clip tr-clip tl-clip-x t-clip-x border" className="common-aug-mixin common-aug-tr-mixin">
        <Toast ref={toastRef} />
        <div className="p-3 card bg-transparent h-30rem">
          <div className="card flex justify-content-end">
            <h2>View Conversations</h2>
          </div>
          <div className="card flex flex-wrap gap-3 p-fluid">
            <div className="flex-auto mt-3">
              <FloatLabel>
                <label htmlFor="summary">Calls Made After</label>
                <Calendar
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.value as Date)}
                  showTime
                  hourFormat="24"
                  dateFormat="D, M d, yy 'UTC'"
                  showIcon
                />
              </FloatLabel>
            </div>
            <div className="flex-auto mt-3">
              <FloatLabel>
                <label htmlFor="summary">Calls Made Before</label>
                <Calendar
                  value={calledByDate}
                  onChange={(e) => setCalledByDate(e.value as Date)}
                  showTime
                  hourFormat="24"
                  dateFormat="D, M d, yy 'UTC'"
                  showIcon
                />
              </FloatLabel>
            </div>
          </div>
          <div className="mt-4">
            <label>Min Responses: {targetResponseCount}</label>
            <Slider
              value={targetResponseCount}
              onChange={(e) => setTargetResponseCount(e.value as number)}
              min={0}
              max={10}
            />
          </div>
          <div className="mt-4">
            <InputSwitch
              checked={answeredByIVR}
              onChange={(e) => setAnsweredByIVR(e.value)}
            />
            <span className="vertical-align-top ml-2">Filter by: IVR Answered</span>
          </div>
          <div className="mt-3">
            <InputSwitch
              checked={includeOutboundCalls}
              onChange={(e) => setIncludeOutboundCalls(e.value)}
            />
            <span className="vertical-align-top ml-2">Include Outbound Calls</span>
          </div>
          <div className="mt-3">
            <InputSwitch
              checked={includeInboundCalls}
              onChange={(e) => setIncludeInboundCalls(e.value)}
            />
            <span className="vertical-align-top ml-2">Include Inbound Calls</span>
          </div>
          <div className="mt-3 card flex flex-wrap justify-content-center gap-3">
            <Button
              data-augmented-ui="tl-clip br-clip tr-clip bl-clip "
              label="Important Calls"
              icon="pi pi-comments"
              loading={loading}
              onClick={handleFetchCallData}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-content-center flex-wrap gap-3">
        <div className="card flex justify-content-center">
          <Sidebar
            visible={visible}
            onHide={() => setVisible(false)}
            fullScreen
            className="custom-sidebar"
            style={{ backgroundColor: 'var(--bg-color-darker)' }}
            header={
              <div>
                <div className="card flex flex-wrap align-items-center gap-3">
                  <h2 style={{ color: 'var(--text-color)' }}>Call Data</h2>
                  <div className="flex flex-wrap p-align-center p-justify-start gap-3">
                    <Tag value={`Total Calls: ${callData.length}`} severity="info" />
                    <Tag value={`Inbound: ${countInboundCalls()}`} severity="success" />
                    <Tag value={`Outbound: ${countOutboundCalls()}`} severity="warning" />
                  </div>
                  <Button
                    icon="pi pi-refresh"
                    label="Refresh"
                    onClick={handleRefresh}
                    className="p-button-text p-button-rounded refresh-button"
                    style={{ backgroundColor: 'var(--bg-color-dark)', color: 'var(--text-color)' }}
                  />

                  <Inplace closable>
                    <InplaceDisplay>Current Filters +</InplaceDisplay>
                    <InplaceContent>
                      <div className="p-grid p-align-center p-justify-start gap-3">
                        <Tag
                          className="mr-2"
                          value={`Response Count >= ${targetResponseCount}`}
                          severity={
                            targetResponseCount !== 0 ? "success" : "warning"
                          }
                        />
                        <Tag
                          className="mr-2"
                          value={`IVR Answered: ${answeredByIVR ? "Yes" : "No"}`}
                          severity={answeredByIVR ? "success" : "warning"}
                        />
                        <Tag
                          className="mr-2"
                          value={`Outbound Calls: ${includeOutboundCalls ? "Yes" : "No"}`}
                          severity={includeOutboundCalls ? "success" : "warning"}
                        />
                        <Tag
                          className="mr-2"
                          value={`Inbound Calls: ${includeInboundCalls ? "Yes" : "No"}`}
                          severity={includeInboundCalls ? "success" : "warning"}
                        />
                        <Tag
                          className="mr-2"
                          value={`Called After: ${selectedDate.toISOString()}`}
                          severity={selectedDate ? "success" : "warning"}
                        />
                        {calledByDate && (
                          <Tag
                            className="mr-2"
                            value={`Called Before: ${calledByDate.toISOString()}`}
                            severity={calledByDate ? "success" : "warning"}
                          />
                        )}
                      </div>
                    </InplaceContent>
                  </Inplace>
                </div>

                <Divider />
              </div>
            }
          >
            <div className="call-data-body">
              {callData.length > 0 ? (
                callData.map((data, index) => (
                  <div key={index} className="flex flex-wrap" id={`call-data-${index}`}>
                    <Card
                      title={
                        <div className="flex justify-content-between flex-wrap">
                          <div className="flex align-items-center">
                            <i className="pi pi-building mr-2"></i>
                            <span className='text-2xl'>{data.companyName}</span>
                            <span className='text-xl ml-3' style={{ color: '#007bff' }}>{data.targetPhoneNumberE164}</span>
                            <span className='ml-3 uppercase'>{data.callType}</span>
                          </div>
                          <div>
                            <Inplace closable>
                              <InplaceDisplay>Call Details +</InplaceDisplay>
                              <InplaceContent>
                                <div className="call-details">
                                  <div className="flex justify-content-between flex-wrap">
                                    <div className="p-col-12 p-md-6">
                                      <p><i className="pi pi-clock mr-2"></i>Duration: {data.callDurationSeconds} sec</p>
                                      <p><i className="pi pi-calendar mr-2"></i>End Time: {data.callEndTime}</p>
                                      <p><i className="pi pi-globe mr-2"></i>State: {data.state}</p>
                                      <p><i className="pi pi-briefcase mr-2"></i>Industry: {data.industry}</p>
                                    </div>
                                    <div className="p-col-12 p-md-6 flex align-items-center justify-content-end">
                                      <div className="toggle-buttons">
                                        <div className="my-2">
                                          <ToggleButton
                                            checked={data.answeredByIVR}
                                            onChange={(e) => handleCallDetailToggleChange(data.detailId, 'answeredByIVR', e.value, 'CallDetails')}
                                            onLabel="IVR Answered"
                                            offLabel="IVR Not Answered"
                                            className="custom-toggle-button"
                                          />
                                        </div>
                                        <div className="my-2">
                                          <ToggleButton
                                            checked={data.targetAnswered}
                                            onChange={(e) => handleCallDetailToggleChange(data.detailId, 'targetAnswered', e.value, 'CallDetails')}
                                            onLabel="Person Answered"
                                            offLabel="No Answer"
                                            className="custom-toggle-button"
                                          />
                                        </div>
                                        <div className="my-2">
                                          <ToggleButton
                                            checked={data.doNotCallList}
                                            onChange={(e) => handleCallDetailToggleChange(data.callListId, 'doNotCallList', e.value, 'CallLists')}
                                            onLabel="Do Not Call"
                                            offLabel="Allow Calls"
                                            className="custom-toggle-button"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <p><i className="pi pi-info-circle mr-2"></i>Call Result: {data.callResult}</p>
                                  {data.callErrors && data.callErrors.length > 0 && (
                                    <div className="error-message">
                                      <Message
                                        severity="error"
                                        text={data.callErrors}
                                        icon="pi pi-exclamation-triangle"
                                      />
                                    </div>
                                  )}
                                </div>
                              </InplaceContent>
                            </Inplace>
                          </div>
                        </div>
                      }
                      className="call-card"
                      style={{ fontFamily: 'var(--font-family-main)', backgroundColor: 'var(--bg-color-dark)', color: 'var(--text-color)' }}
                    >

                      <div className="flex flex-wrap mb-3">
                        <div>
                          <Button
                            icon="pi pi-copy"
                            severity="secondary"
                            onClick={() => captureAndCopyToClipboard(`call-data-${index}`)}
                            className="p-button-rounded mb-2 border-1"
                            tooltip="Copy call details as image"
                            tooltipOptions={{ position: 'bottom' }}
                          />
                        </div>
                        <strong className="mt-2 ml-4">Conversation:</strong>
                      </div>
                      <div>
                        {data.conversationJson ? (
                          renderConversationMessages(data.conversationJson)
                        ) : (
                          <p>No conversation data available.</p>
                        )}
                      </div>

                    </Card>

                    <Divider className="thick-divider" />
                  </div>
                ))
              ) : (
                <p>No call data available for the selected date and time.</p>
              )}
            </div>
          </Sidebar>
        </div>
      </div>
    </div>
  );
};

export default ConversationLogs;
