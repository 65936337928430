// index.tsx is the entry point of the application. It renders the App component into the root element of the HTML document.
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./styles/index.css";
import AppContent from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email read:user_metadata offline_access",
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <AppContent />
      </Auth0Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();

