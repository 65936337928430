// File: src/components/CallMetricsHUD.tsx
import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Chart } from 'primereact/chart';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useAuth0 } from '@auth0/auth0-react';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// Import and register Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
  Title,
  PointElement,
  LineElement,
} from 'chart.js'; 
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FloatLabel } from 'primereact/floatlabel';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  ChartTooltip,
  Legend,
  Title,
  PointElement,
  LineElement,
  ChartDataLabels
);

interface CallMetricsHUDProps {
  tenantId: string | null;
}

interface MetricsData {
  metricId: string;
  tenantId: string | null;
  date?: string;
  yearWeek?: number;
  yearMonth?: number;
  totalCalls: number;
  totalInboundCalls: number;
  totalOutboundCalls: number;
  totalCallDurationSeconds: number;
  totalOutboundLeftMessageCount: number;
  totalOutboundAnsweredByIVRCount: number;
  totalOutboundAnsweredCount: number;
  totalMaxCallDurationReachedCount: number;
  totalErrorCount: number;
  successfulCallsCount: number;
  failedCallsCount: number;
  answeredCallCount: number;
  sumAnsweredCallDurationSeconds: number;
  sumAnsweredCallResponseTimeMs: number;
  sumAnsweredCallResponseCount: number;
  sumAnsweredCallErrorCount: number;
  avgAnsweredCallDurationSeconds: number;
  avgAnsweredCallResponseTimeMs: number;
  avgAnsweredCallResponseCount: number;
  avgAnsweredCallErrorCount: number;
  maxCallDurationSeconds: number;
  minCallDurationSeconds: number;
}

const CallMetricsHUD: React.FC<CallMetricsHUDProps> = ({ tenantId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [metrics, setMetrics] = useState<MetricsData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [visibleSidebar, setVisibleSidebar] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<string>('daily');
  const timeRangeOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
  ];

  const toastRef = React.useRef<Toast>(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/metrics/hud/${timeRange}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            setMetrics(null);
          } else {
            throw new Error(`Error fetching metrics: ${response.statusText}`);
          }
        } else {
          const data = await response.json();

          // Ensure numeric fields are numbers and property names are in camelCase
          const parsedData: MetricsData = {
            metricId: data.metricId,
            tenantId: data.tenantId,
            date: data.date,
            yearWeek: data.yearWeek,
            yearMonth: data.yearMonth,
            totalCalls: Number(data.totalCalls || 0),
            totalInboundCalls: Number(data.totalInboundCalls || 0),
            totalOutboundCalls: Number(data.totalOutboundCalls || 0),
            totalCallDurationSeconds: Number(data.totalCallDurationSeconds || 0),
            totalOutboundLeftMessageCount: Number(data.totalOutboundLeftMessageCount || 0),
            totalOutboundAnsweredByIVRCount: Number(data.totalOutboundAnsweredByIVRCount || 0),
            totalOutboundAnsweredCount: Number(data.totalOutboundAnsweredCount || 0),
            totalMaxCallDurationReachedCount: Number(data.totalMaxCallDurationReachedCount || 0),
            totalErrorCount: Number(data.totalErrorCount || 0),
            successfulCallsCount: Number(data.successfulCallsCount || 0),
            failedCallsCount: Number(data.failedCallsCount || 0),
            answeredCallCount: Number(data.answeredCallCount || 0),
            sumAnsweredCallDurationSeconds: Number(data.sumAnsweredCallDurationSeconds || 0),
            sumAnsweredCallResponseTimeMs: Number(data.sumAnsweredCallResponseTimeMs || 0),
            sumAnsweredCallResponseCount: Number(data.sumAnsweredCallResponseCount || 0),
            sumAnsweredCallErrorCount: Number(data.sumAnsweredCallErrorCount || 0),
            avgAnsweredCallDurationSeconds: Number(data.avgAnsweredCallDurationSeconds || 0),
            avgAnsweredCallResponseTimeMs: Number(data.avgAnsweredCallResponseTimeMs || 0),
            avgAnsweredCallResponseCount: Number(data.avgAnsweredCallResponseCount || 0),
            avgAnsweredCallErrorCount: Number(data.avgAnsweredCallErrorCount || 0),
            maxCallDurationSeconds: Number(data.maxCallDurationSeconds || 0),
            minCallDurationSeconds: Number(data.minCallDurationSeconds || 0),
          };

          setMetrics(parsedData);
        }
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'An unexpected error occurred';
        setError(errorMessage);
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [getAccessTokenSilently, timeRange]);

  // Format seconds to HH:MM:SS
  const formatSecondsToHMS = (seconds: number) => {
    if (isNaN(seconds)) return '0h 0m 0s';
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}h ${m}m ${s}s`;
  };

  // Format seconds to MM:SS
  const formatSecondsToMinSec = (seconds: number) => {
    if (isNaN(seconds)) return '0m 0s';
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m}m ${s}s`;
  };

  const renderMiniHUD = () => {
    if (!metrics) {
      return <p>No metrics available for {timeRange}.</p>;
    }

    return (
      <div>
        <div className="p-2 flex align-items-stretch flex-wrap">
          <FloatLabel>
            <label htmlFor="timeRange">Select Range</label>
            <Dropdown
              id="timeRange"
              value={timeRange}
              options={timeRangeOptions}
              onChange={(e) => setTimeRange(e.value)}
              placeholder="Select Time Range"
              className="mr-2"
            />
          </FloatLabel>
          <Button
            icon="pi pi-angle-up"
            onClick={() => setVisibleSidebar(true)}
            className="p-button-rounded p-button-text m-1"
            tooltip="View Details"
          />
        </div>
        <div className="flex p-justify-between">
          <div className="flex align-items-stretch flex-wrap">
            <div className="p-2 p-text-center border-1 border-round-md m-2 w-10rem">
              <div className="flex align-items-stretch flex-wrap">
                <i className="pi pi-phone p-text-secondary text-3xl font-semibold p-2" />
                <span className="text-3xl font-semibold">{metrics.totalInboundCalls}</span>
              </div>
              <span className="text-lg">Inbound Calls</span>
            </div>
            <div className="p-2 p-text-center border-1 border-round-md m-2 w-10rem">
              <div className="flex align-items-stretch flex-wrap">
                <i className="pi pi-external-link p-text-secondary text-3xl font-semibold p-2" />
                <span className="text-3xl font-semibold">{metrics.totalOutboundCalls}</span>
              </div>
              <span className="text-lg">Outbound Calls</span>
            </div>
            <div className="p-2 p-text-center border-1 border-round-md m-2 w-10rem">
              <div className="flex align-items-stretch flex-wrap">
                <i className="pi pi-clock p-text-secondary text-3xl font-semibold p-2" />
                <span className="text-2xl font-semibold">
                  {formatSecondsToMinSec(metrics.avgAnsweredCallDurationSeconds)}
                </span>
              </div>
              <span className="text-lg">Avg Call Duration</span>
            </div>
            <div className="p-2 p-text-center border-1 border-round-md m-2 w-10rem">
              <div className="flex align-items-stretch flex-wrap">
                <i className="pi pi-comments p-text-secondary text-3xl font-semibold p-2" />
                <span className="text-3xl font-semibold">
                  {metrics.avgAnsweredCallResponseCount}
                </span>
              </div>
              <span className="text-lg">Avg Call Responses</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCharts = () => {
    if (!metrics) return <p>No data available.</p>;

    // Prepare data for charts, using camelCase property names
    const inboundOutboundData = {
      labels: ['Inbound Calls', 'Outbound Calls'],
      datasets: [
        {
          data: [metrics.totalInboundCalls, metrics.totalOutboundCalls],
          backgroundColor: ['#42A5F5', '#66BB6A'],
          hoverBackgroundColor: ['#64B5F6', '#81C784'],
        },
      ],
    };

    const callDurationData = {
      labels: ['Average', 'Maximum', 'Minimum'],
      datasets: [
        {
          label: 'Duration (s)',
          data: [
            metrics.avgAnsweredCallDurationSeconds,
            metrics.maxCallDurationSeconds,
            metrics.minCallDurationSeconds,
          ],
          backgroundColor: ['#FFA726', '#EF5350', '#66BB6A'],
          hoverBackgroundColor: ['#FFB74D', '#E57373', '#81C784'],
        },
      ],
    };

    const responseCountData = {
      labels: ['Avg Responses per Call'],
      datasets: [
        {
          label: 'Responses',
          data: [metrics.avgAnsweredCallResponseCount],
          backgroundColor: ['#AB47BC'],
          hoverBackgroundColor: ['#BA68C8'],
        },
      ],
    };

    const successFailureData = {
      labels: ['Successful Calls', 'Failed Calls'],
      datasets: [
        {
          data: [metrics.successfulCallsCount, metrics.failedCallsCount],
          backgroundColor: ['#66BB6A', '#EF5350'],
          hoverBackgroundColor: ['#81C784', '#E57373'],
        },
      ],
    };

    const outboundCallDetailsData = {
      labels: ['Answered', 'Left Message', 'Answered by IVR'],
      datasets: [
        {
          data: [
            metrics.totalOutboundAnsweredCount,
            metrics.totalOutboundLeftMessageCount,
            metrics.totalOutboundAnsweredByIVRCount,
          ],
          backgroundColor: ['#42A5F5', '#FFCA28', '#26C6DA'],
          hoverBackgroundColor: ['#64B5F6', '#FFD54F', '#4DD0E1'],
        },
      ],
    };

    const errorCountData = {
      labels: ['Total Errors', 'Avg Errors per Call'],
      datasets: [
        {
          label: 'Errors',
          data: [metrics.totalErrorCount, metrics.avgAnsweredCallErrorCount],
          backgroundColor: ['#E53935', '#D32F2F'],
          hoverBackgroundColor: ['#EF5350', '#E57373'],
        },
      ],
    };

    // Chart options with data labels
    const chartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'top' as const,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          color: '#fff',
          formatter: (value: number) => value,
          font: {
            weight: 'bold' as const,
          },
        },
      },
      maintainAspectRatio: false,
    };

    const smallChartStyle: React.CSSProperties = {
      position: 'relative',
      width: '100%',
      height: '250px',
    };

    return (
      <div className="flex align-items-stretch flex-wrap justify-content-center gap-8">
        <div className="card m-2 p-2" >
          <h3 className="text-center">Inbound vs Outbound Calls</h3>
          <div style={smallChartStyle}>
            <Chart className="w-20rem h-20rem"    type="pie" data={inboundOutboundData} options={chartOptions} />
          </div>
          <p className="text-center sm:mt-8">
            Total Calls: {metrics.totalCalls}
          </p>
        </div>
        <div className="card m-2 p-2">
          <h3 className="text-center">Call Success vs Failure</h3>
          <div style={smallChartStyle}>
            <Chart className="w-20rem h-20rem"    type="doughnut" data={successFailureData} options={chartOptions} />
          </div>
          <p className="text-center sm:mt-8">
            Success Rate: {(
              (metrics.successfulCallsCount / metrics.totalCalls) *
              100
            ).toFixed(2)}
            %
          </p>
        </div>
        <div className="card m-2 p-2">
          <h3 className="text-center">Call Duration</h3>
          <div style={smallChartStyle}>
            <Chart className="w-20rem h-20rem" 
              type="bar"
              data={callDurationData}
              options={{
                ...chartOptions,
                scales: {
                  x: { display: true },
                  y: { display: true },
                },
              }}
            />
          </div>
          <p className="text-center sm:mt-8">
            Total Duration: {formatSecondsToMinSec(metrics.totalCallDurationSeconds)}
          </p>
        </div>
        <div className="card m-2 p-2">
          <h3 className="text-center">Avg Responses per Call</h3>
          <div style={smallChartStyle}>
            <Chart className="w-20rem h-20rem" 
              type="bar"
              data={responseCountData}
              options={{
                ...chartOptions,
                indexAxis: 'y',
                scales: {
                  x: { display: true },
                  y: { display: true },
                },
              }}
            />
          </div>
          <p className="text-center sm:mt-8">
            Total Responses: {metrics.sumAnsweredCallResponseCount}
          </p>
        </div>
        <div className="card m-2 p-2">
          <h3 className="text-center">Outbound Call Details</h3>
          <div style={smallChartStyle}>
            <Chart className="w-20rem h-20rem"    type="pie" data={outboundCallDetailsData} options={chartOptions} />
          </div>
          <p className="text-center sm:mt-8">
            Total Outbound Calls: {metrics.totalOutboundCalls}
          </p>
        </div>
        {/* <div className="card m-2 p-2">
          <h3 className="text-center">Error Counts</h3>
          <div style={smallChartStyle}>
            <Chart className="w-20rem h-20rem" 
              type="bar"
              data={errorCountData}
              options={{
                ...chartOptions,
                scales: {
                  x: { display: true },
                  y: { display: true },
                },
              }}
            />
          </div>
          <p className="text-center mt-2">
            Total Errors: {metrics.totalErrorCount}
          </p>
        </div> */}
        {/* Detailed Metrics */}
        <div className="card m-2 p-2" style={{ width: '90%' }} data-augmented-ui="tl-clip tr-clip br-clip bl-clip border" >
          <h3 className="text-center">Detailed Metrics</h3>
          <div className="p-grid">
            <div className="p-col-12 p-md-6">
              <ul>
                <li>
                  <strong>Total Calls:</strong> {metrics.totalCalls}
                </li>
                <li>
                  <strong>Answered Calls:</strong> {metrics.answeredCallCount}
                </li>
                {/* <li>
                  <strong>Total Errors:</strong> {metrics.totalErrorCount}
                </li> */}
                <li>
                  <strong>Max Call Duration Reached:</strong>{' '}
                  {metrics.totalMaxCallDurationReachedCount}
                </li>
                <li>
                  <strong>Total Call Duration:</strong>{' '}
                  {formatSecondsToMinSec(metrics.totalCallDurationSeconds)}
                </li>
              </ul>
            </div>
            <div className="p-col-12 p-md-6">
              <ul>
                <li>
                  <strong>Sum Answered Call Duration:</strong>{' '}
                  {formatSecondsToMinSec(metrics.sumAnsweredCallDurationSeconds)}
                </li>
                <li>
                  <strong>Avg Response Time:</strong>{' '}
                  {metrics.avgAnsweredCallResponseTimeMs.toFixed(2)} ms
                </li>
                {/* <li>
                  <strong>Avg Error Count per Call:</strong>{' '}
                  {metrics.avgAnsweredCallErrorCount.toFixed(2)}
                </li> */}
                <li>
                  <strong>Sum Response Count:</strong>{' '}
                  {metrics.sumAnsweredCallResponseCount}
                </li>
                {/* <li>
                  <strong>Sum Error Count:</strong>{' '}
                  {metrics.sumAnsweredCallErrorCount}
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-2 m-2" style={{ width: '100%', height: '100%' }}>
      <Toast ref={toastRef} />
      <>
        {loading ? (
          <div
            className="p-d-flex p-jc-center p-ai-center"
            style={{ height: '150px' }}
          >
            <ProgressSpinner />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          renderMiniHUD()
        )}
      </>

      <Sidebar
        visible={visibleSidebar}
        onHide={() => setVisibleSidebar(false)}
        position="bottom"
        fullScreen
        style={{ height: '80%', width: '95%' }}
        appendTo={document.body}
      >
        <div
          className="flex flex-column"
          style={{ overflowY: 'auto', maxHeight: '100%' }}
        >
          <div className="flex align-items-center justify-content-center">
            <h2>
              {timeRange.charAt(0).toUpperCase() + timeRange.slice(1)} Metrics Details
            </h2>
          </div>
          <div className="flex align-items-center justify-content-center">
            {renderCharts()}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default CallMetricsHUD;
